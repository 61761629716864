import React, { useEffect, useRef, useState } from "react";
import Input from "../../../Components/Input/Input";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertFromRaw, convertToRaw } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Styles from "./EditBlogPost.module.css";
import Button from "../../../Components/Button/Button";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { CircularProgress } from "@mui/material";
import setAuthToken from "../../../utils/setAuthToken";
import Modal from "../../../Components/Modal/Modal";
const EditBlogPost = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    blog_title: "",
    blog_subtitle: "",
    blog_content: "",
    blog_category: "",
    blog_microsite: [],
    // {
    //   Rule_Of_40: false,
    //   "13F_Reports": false,
    //   Fintech_News: false,
    // },
  });
  const [message, setMessage] = useState("");
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [modalOpen, setModalOpen] = useState(false);
  const [multiSelectionModal, setMultiSelectionModal] = useState(false);
  console.log(setModalOpen);
  const multiOptions = [
    {
      name: "Rule Of 40",
      value: "Rule_Of_40",
      checked: formData.blog_microsite.includes("Rule_Of_40"),
    },
    {
      name: "13F Reports",
      value: "13F_Reports",
      checked: formData.blog_microsite.includes("13F_Reports"),
    },
    {
      name: "Fintech News",
      value: "Fintech_News",
      checked: formData.blog_microsite.includes("Fintech_News"),
    },
    {
      name: "Market Movers",
      value: "Market_Movers",
      checked: formData.blog_microsite.includes("Market_Movers"),
    },
  ];
  useEffect(() => {
    const fetchBlogPost = async () => {
      try {
        setLoading(true);
        const blogPostResponse = await axios.get(`/api/blog/${id}`);
        setFormData({
          blog_title: blogPostResponse?.data[0]?.blog_title,
          blog_subtitle: blogPostResponse?.data[0]?.blog_subtitle,
          blog_content: blogPostResponse?.data[0]?.blog_content,
          blog_category: blogPostResponse?.data[0]?.blog_category,
          blog_microsite: blogPostResponse?.data[0]?.blog_microsite.split(","),
        });

        if (blogPostResponse?.data[0]?.blog_content) {
          const contentState = convertFromRaw(
            JSON.parse(blogPostResponse?.data[0]?.blog_content)
          );
          setEditorState(EditorState.createWithContent(contentState));
        }
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };

    fetchBlogPost();
  }, [id]);

  const onEditorStateChange = (e) => {
    setEditorState(e);
    const rawContentState = convertToRaw(editorState.getCurrentContent());
    setFormData((prevState) => {
      return { ...prevState, blog_content: JSON.stringify(rawContentState) };
    });
  };

  const handleFormValues = (e, key) => {
    if (key === undefined) {
      setFormData((prev) => {
        const updatedMicrosite = prev.blog_microsite.includes(e.target.value)
          ? prev.blog_microsite.filter((v) => v !== e.target.value)
          : [...prev.blog_microsite, e.target.value];

        return {
          ...prev,
          blog_microsite: updatedMicrosite,
        };
      });
    } else {
      setFormData((prev) => {
        return {
          ...prev,
          [key]: e.target.value,
        };
      });
    }
  };

  const stringFormatting = (array) => {
    if (array.length === 0) return "";
    const values = array.map((v) => v.replace(/_/g, " "));
    const stringJoin = values.join(", ");
    return stringJoin;
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const body = {
        title: formData.blog_title,
        subtitle: formData.blog_subtitle,
        content: formData.blog_content,
        category: formData.blog_category,
        id: id,
        microsites: formData.blog_microsite.join(","),
      };
      const emptyValues = Object.values(body).some((v) => v === "");
      const micrositesEmpty = stringFormatting(formData.blog_microsite);
      if (emptyValues || micrositesEmpty === "") {
        setLoading(false);
        setMessage("Please fill all fields");
      } else {
        setAuthToken(localStorage.getItem("tokenLogin"));
        const editBlogPost = await axios.post("/api/blog/update", body);
        if (editBlogPost.status === 200) {
          setMessage(editBlogPost.data);
          setLoading(false);
        }
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  // const onDelete = async () => {
  //   try {
  //     setLoading(true);
  //     setAuthToken(localStorage.getItem("tokenLogin"));
  //     const deletePost = await axios.delete(`/api/blog/${id}`);
  //     setLoading(false);
  //     setMessage(deletePost.data);
  //     setModalOpen(true);
  //   } catch (error) {
  //     console.log(error);
  //     setLoading(false);
  //   }
  // };

  const handleValueInputMultiSelection = (obj) => {
    if (!obj) {
      return "Website";
    } else if (Object.keys(obj).every((key) => obj[key] === false)) {
      return "Website";
    } else {
      return stringFormatting(formData.blog_microsite);
    }
  };

  const modalRef = useRef(null);

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      setMultiSelectionModal(false);
    }
  };
  useEffect(() => {
    if (multiSelectionModal) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [multiSelectionModal]);

  return (
    <div className={Styles.App}>
      {!loading ? (
        <form className={Styles.ContentWrapper} onSubmit={onSubmit}>
          <div className={Styles.TopWrapper}>
            <div className={Styles.MessageWrapper}>{message}</div>
            <div className={Styles.ButtonHolder}>
              {/* <Button
                variant={"danger"}
                onClick={onDelete}
                style={{
                  width: "200px",
                  height: "30px",
                  borderRadius: "5px",
                }}
              >
                Remove Post
              </Button> */}
              <Button
                type={"submit"}
                style={{
                  width: "200px",
                  height: "30px",
                  borderRadius: "5px",
                }}
              >
                Submit
              </Button>
            </div>
          </div>
          <div className={Styles.FormGrid}>
            <div className={Styles.FormLeftGrid}>
              <Input
                label
                textLabel={"Title"}
                placeholder={"Blog Title"}
                onChange={(e) => handleFormValues(e, "blog_title")}
                value={formData.blog_title}
              />
              <Input
                label
                textLabel={"Subtitle"}
                placeholder={"Blog Subtitle"}
                onChange={(e) => handleFormValues(e, "blog_subtitle")}
                value={formData.blog_subtitle}
              />
              <Input
                label
                textLabel={"Category"}
                placeholder={"Category"}
                onChange={(e) => handleFormValues(e, "blog_category")}
                value={formData.blog_category}
              />
              <Input
                label
                textLabel={"Website"}
                placeholder={"Website"}
                type={"multiselect"}
                value={handleValueInputMultiSelection(formData.blog_microsite)}
                optionsMultiSelection={multiOptions}
                onChange={handleFormValues}
                multiSelectionModal={multiSelectionModal}
                setMultiSelectionModal={setMultiSelectionModal}
                modalRef={modalRef}
              />
            </div>
            <div>
              <Editor
                id="contentEditor"
                editorState={editorState}
                onEditorStateChange={onEditorStateChange}
                wrapperClassName={Styles.CmsWrapper}
                editorClassName={Styles.CmsEditor}
                handlePastedText={() => false}
              />
            </div>
          </div>
        </form>
      ) : (
        <div>
          <CircularProgress />
        </div>
      )}
      <Modal open={modalOpen} setOpen={() => navigate("/blog")}>
        <div className={Styles.MessageWrapper}>{message}</div>
      </Modal>
    </div>
  );
};

export default EditBlogPost;
