import React, { useState } from "react";
import Styles from "./Sidebar.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import hamburger from "../../images/hamburger.svg";
import RightSideModal from "../../Components/RightSideModal/RightSideModal";

const Sidebar = () => {
  const navigate = useNavigate();
  const [rightSideModal, setRightSideModal] = useState(false);
  const { pathname } = useLocation();
  const handleNavigateAbout = () => {
    window.open("https://www.levelfields.ai/about", "_blank");
  };

  const handleNavigateNews = () => {
    window.open("https://fintechnews.levelfields.ai/");
  };

  const navOptions = [
    {
      name: "Price Movers",
      path: "/",
    },
    {
      name: "ETFs",
      path: "/etfs",
    },
    {
      name: "Volume Movers",
      path: "/volume-movers",
    },
    {
      name: "Blog",
      path: "/blog",
    },
    {
      name: "About",
      func: handleNavigateAbout,
    },

    {
      name: "News",
      func: handleNavigateNews,
    },
    {
      name: "Contact",
      path: "/contact-us",
    },
    {
      name: "Login",
      path: "/login",
    },
  ];
  return (
    <div className={Styles.SidebarWrapper}>
      <h3>Movers</h3>
      <ul>
        <li
          onClick={() => navigate("/")}
          style={
            pathname === "/" ? { backgroundColor: "var(--color-stroke)" } : null
          }
        >
          Price Movers
        </li>
        <li
          onClick={() => navigate("/etfs")}
          style={
            pathname === "/etfs"
              ? { backgroundColor: "var(--color-stroke)" }
              : null
          }
        >
          ETFs
        </li>
        <li
          onClick={() => navigate("/volume-movers")}
          style={
            pathname === "/volume-movers"
              ? { backgroundColor: "var(--color-stroke)" }
              : null
          }
        >
          Volume Movers
        </li>
        <li
          onClick={() => navigate("/blog")}
          style={
            pathname === "/blog"
              ? { backgroundColor: "var(--color-stroke)" }
              : null
          }
        >
          Blog
        </li>
        <li onClick={handleNavigateAbout}>About</li>
        <li onClick={handleNavigateNews}>News</li>
        <li
          onClick={() => navigate("/contact-us")}
          style={
            pathname === "/contact-us"
              ? { backgroundColor: "var(--color-stroke)" }
              : null
          }
        >
          Contact
        </li>
        <li
          onClick={() => navigate("/login")}
          style={
            pathname === "/login"
              ? { backgroundColor: "var(--color-stroke)" }
              : null
          }
        >
          Login
        </li>
      </ul>
      <div className={Styles.Hamburger} onClick={() => setRightSideModal(true)}>
        <img src={hamburger} alt="hamburger" width={20} />
      </div>
      <RightSideModal
        open={rightSideModal}
        setOpen={setRightSideModal}
        options={navOptions}
      />
    </div>
  );
};

export default Sidebar;
