import React, { useState, useEffect } from "react";
import CustomPagination from "../../ComponentLayout/Pagination/ui/Pagination";
import Table from "../../Components/Table/Table";
import Styles from "./ETFs.module.css";
import axios from "axios";
import arrowDown from "../../images/arrowDown.svg";
import arrowUp from "../../images/arrowUp.svg";
import { CircularProgress } from "@mui/material";
import { publicIpv4 } from "public-ip";
import Modal from "../../Components/Modal/Modal.js";
import { useNavigate } from "react-router-dom";
import Button from "../../Components/Button/Button.js";

const ETFs = () => {
  const navigate = useNavigate();
  const [losers, setLosers] = useState([]);
  const [gainers, setGainers] = useState([]);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [view, setView] = useState("gainers");
  const [exchange, setExchange] = useState([]);
  const [paginationData, setPaginationData] = useState([]);
  const [limitModal, setLimitModal] = useState(false);
  const [popUpInitial, setPopUpInitial] = useState(false);
  const [sortField, setSortField] = useState("price_change");
  const [sortType, setSortType] = useState("DESC");
  const [filters, setFilters] = useState({
    scalemarketcap: "",
    volume: "",
    close: "",
    stock_exchange: "",
  });
  const marketcapOpt = [
    ["Market Cap", ""],
    ["Micro", [0, 250000000]],
    ["Small", [250000000, 1000000000]],
    ["Med", [1000000000, 10000000000]],
    ["High", [10000000000, ""]],
  ];
  const volumeOpt = [
    ["Volume", ""],
    ["Low", [0, 60000]],
    ["Med", [60000, 600000]],
    ["High", [600000, ""]],
  ];
  const priceRangeOpt = [
    ["Price Range", ""],
    ["0$-10$", [0, 10]],
    ["10$-30$", [10, 30]],
    ["30$-60$", [30, 60]],
    ["60$-100$", [60, 100]],
    [">100$", [100, ""]],
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const body = {
          exchange: filters.stock_exchange,
          priceRange: filters.close,
          volume: filters.volume,
          marketcap: filters.scalemarketcap,
          sortField: sortField,
          sortType: sortType,
        };
        if (view === "gainers") {
          const values = await axios.post("/api/movers/etfs/gainers", body);
          setGainers(values.data.result);
          setExchange(values.data.exchange);
          setLoading(false);
        } else {
          const values = await axios.post("/api/movers/etfs/losers", body);
          setLosers(values.data.result);
          setExchange(values.data.exchange);
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
      }
    };
    fetchData();
  }, [
    view,
    filters.stock_exchange,
    filters.industry,
    filters.scalemarketcap,
    filters.close,
    filters.sector,
    filters.volume,
    sortField,
    sortType,
  ]);

  useEffect(() => {
    if (view === "gainers") {
      setPaginationData(
        gainers.slice(rowsPerPage * page, rowsPerPage * page + rowsPerPage)
      );
    } else {
      setPaginationData(
        losers.slice(rowsPerPage * page, rowsPerPage * page + rowsPerPage)
      );
    }
  }, [rowsPerPage, page, losers, gainers, view]);

  const columns = [
    {
      accessorKey: "symbol",
      header: () => (
        <div
          onClick={() => {
            setSortField("symbol");
            setSortType((prev) => (prev === "ASC" ? "DESC" : "ASC"));
          }}
          className={Styles.Headers}
        >
          Symbol
          {sortField === "symbol" ? (
            sortType === "DESC" ? (
              <img src={arrowUp} alt="up" width={15} height={15} />
            ) : (
              <img src={arrowDown} alt="down" width={15} height={15} />
            )
          ) : null}
        </div>
      ),
    },
    {
      accessorKey: "company_name",
      header: () => (
        <div
          onClick={() => {
            setSortField("company_name");
            setSortType((prev) => (prev === "ASC" ? "DESC" : "ASC"));
          }}
          className={Styles.Headers}
        >
          Company Name{" "}
          {sortField === "company_name" ? (
            sortType === "DESC" ? (
              <img src={arrowUp} alt="up" width={15} height={15} />
            ) : (
              <img src={arrowDown} alt="down" width={15} height={15} />
            )
          ) : null}
        </div>
      ),
    },
    {
      accessorKey: "close",
      header: () => (
        <div
          onClick={() => {
            setSortField("close");
            setSortType((prev) => (prev === "ASC" ? "DESC" : "ASC"));
          }}
          className={Styles.Headers}
        >
          Close{" "}
          {sortField === "close" ? (
            sortType === "DESC" ? (
              <img src={arrowUp} alt="up" width={15} height={15} />
            ) : (
              <img src={arrowDown} alt="down" width={15} height={15} />
            )
          ) : null}
        </div>
      ),
      cell: ({ row }) => {
        return <div>${row.original.close}</div>;
      },
    },
    {
      accessorKey: "price_change",
      header: () => (
        <div
          onClick={() => {
            setSortField("price_change");
            setSortType((prev) => (prev === "ASC" ? "DESC" : "ASC"));
          }}
          className={Styles.Headers}
        >
          CHG%{" "}
          {sortField === "price_change" ? (
            sortType === "DESC" ? (
              <img src={arrowUp} alt="up" width={15} height={15} />
            ) : (
              <img src={arrowDown} alt="down" width={15} height={15} />
            )
          ) : null}
        </div>
      ),
      cell: ({ row }) => {
        const value = +row.original.price_change;
        return (
          <div className={value < 0 ? Styles.Red : Styles.Green}>{value}%</div>
        );
      },
    },
    {
      accessorKey: "volume",
      header: () => (
        <div
          onClick={() => {
            setSortField("volume");
            setSortType((prev) => (prev === "ASC" ? "DESC" : "ASC"));
          }}
          className={Styles.Headers}
        >
          Volume{" "}
          {sortField === "volume" ? (
            sortType === "DESC" ? (
              <img src={arrowUp} alt="up" width={15} height={15} />
            ) : (
              <img src={arrowDown} alt="down" width={15} height={15} />
            )
          ) : null}
        </div>
      ),
      cell: ({ row }) => {
        return (
          <div>
            {new Intl.NumberFormat("us-US").format(row.original.volume)}
          </div>
        );
      },
    },
    {
      accessorKey: "scalemarketcap",
      header: () => (
        <div
          onClick={() => {
            setSortField("scalemarketcap");
            setSortType((prev) => (prev === "ASC" ? "DESC" : "ASC"));
          }}
          className={Styles.Headers}
        >
          Market Cap{" "}
          {sortField === "scalemarketcap" ? (
            sortType === "DESC" ? (
              <img src={arrowUp} alt="up" width={15} height={15} />
            ) : (
              <img src={arrowDown} alt="down" width={15} height={15} />
            )
          ) : null}
        </div>
      ),
      cell: ({ row }) => {
        return (
          <div>
            {new Intl.NumberFormat("us-US").format(row.original.scalemarketcap)}
          </div>
        );
      },
    },
    {
      accessorKey: "date",
      header: () => (
        <div
          onClick={() => {
            setSortField("date");
            setSortType((prev) => (prev === "ASC" ? "DESC" : "ASC"));
          }}
          className={Styles.Headers}
        >
          Date{" "}
          {sortField === "date" ? (
            sortType === "DESC" ? (
              <img src={arrowUp} alt="up" width={15} height={15} />
            ) : (
              <img src={arrowDown} alt="down" width={15} height={15} />
            )
          ) : null}
        </div>
      ),
      cell: ({ row }) => {
        const date = new Date(row.original.date);
        return <div>{new Intl.DateTimeFormat("en-US").format(date)}</div>;
      },
    },
  ];

  useEffect(() => {
    const isIpChecked = sessionStorage.getItem("ipcheck");
    const isPopUpInitial = sessionStorage.getItem("popup-modal");
    if (!isIpChecked) {
      const checkIp = async () => {
        try {
          const body = {
            ip: await publicIpv4(),
          };

          const check = await axios.post("/api/auth/ipchecker", body);
          if (check.status === 200) {
            sessionStorage.setItem("ipcheck", "true");
            if (!isPopUpInitial) {
              sessionStorage.setItem("popup-modal", "true");
              setTimeout(() => setPopUpInitial(true), 5000);
            }
          }
        } catch (error) {
          console.log(error?.response?.data);
          if (
            error?.response?.data?.message === "Limit reached" &&
            error?.response?.data?.count === 3
          ) {
            setLimitModal(true);

            setPopUpInitial(false);
          }
        }
      };
      checkIp();
    } else {
      console.log("ip already checked");
      if (!isPopUpInitial) {
        sessionStorage.setItem("popup-modal", "true");
        setTimeout(() => setPopUpInitial(true), 5000);
      }
    }
  }, []);

  return loading ? (
    <div className={Styles.Loading}>
      <CircularProgress />
    </div>
  ) : (
    <div className={Styles.Wrapper}>
      <div className={Styles.TopWrapper}>
        <h1>{view === "gainers" ? "Gainers" : "Losers"}</h1>
        <div className={Styles.ButtonSwitch}>
          <button
            onClick={() => {
              if (view !== "gainers") {
                setView("gainers");
                setLoading(true);
                setPage(0);
              }
            }}
            className={view === "gainers" ? `${Styles.active}` : null}
          >
            Gainers
          </button>
          <button
            onClick={() => {
              if (view !== "losers") {
                setView("losers");
                setLoading(true);
                setPage(0);
              }
            }}
            className={view === "losers" ? `${Styles.active}` : null}
          >
            Losers
          </button>
        </div>
      </div>
      <div className={Styles.TableWrapper}>
        <div className={Styles.FiltersWrapper}>
          <div className={Styles.FiltersModalInput}>
            <select
              value={filters.scalemarketcap}
              onChange={(e) => {
                setFilters((prev) => {
                  return {
                    ...prev,
                    scalemarketcap: e.target.value,
                  };
                });
              }}
            >
              {marketcapOpt.map((item, i) => {
                return (
                  <option key={i} value={item[1]}>
                    {item[0]}
                  </option>
                );
              })}
            </select>
          </div>
          <div className={Styles.FiltersModalInput}>
            <select
              value={filters.volume}
              onChange={(e) => {
                setFilters((prev) => {
                  return {
                    ...prev,
                    volume: e.target.value,
                  };
                });
              }}
            >
              {volumeOpt.map((item, i) => {
                return (
                  <option key={i} value={item[1]}>
                    {item[0]}
                  </option>
                );
              })}
            </select>
          </div>
          <div className={Styles.FiltersModalInput}>
            <select
              value={filters.close}
              onChange={(e) => {
                setFilters((prev) => {
                  return {
                    ...prev,
                    close: e.target.value,
                  };
                });
              }}
            >
              {priceRangeOpt.map((item, i) => {
                return (
                  <option key={i} value={item[1]}>
                    {item[0]}
                  </option>
                );
              })}
            </select>
          </div>
          <div className={Styles.FiltersModalInput}>
            <select
              value={filters.stock_exchange}
              onChange={(e) => {
                setFilters((prev) => {
                  return {
                    ...prev,
                    stock_exchange: e.target.value,
                  };
                });
              }}
            >
              {exchange.map((item, i) => {
                return (
                  <option key={i} value={item[1]}>
                    {item[0]}
                  </option>
                );
              })}
            </select>
          </div>

          <div className={Styles.FiltersModalInput}>
            <button
              onClick={() =>
                setFilters({
                  scalemarketcap: "",
                  volume: "",
                  close: "",
                  stock_exchange: "",
                  industry: "",
                  sector: "",
                })
              }
            >
              Clear
            </button>
          </div>
        </div>
        <Table data={paginationData} columns={columns} />
        <CustomPagination
          count={view === "gainers" ? gainers.length : losers.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(newPage) => setPage(newPage)}
          onRowsPerPageChange={(newRowsPerPage) => {
            setRowsPerPage(newRowsPerPage);
            setPage(0);
          }}
        />
      </div>
      <Modal open={limitModal} title={"Ops..."}>
        <div className={Styles.ModalBody}>
          <p>
            It looks like you really like our website, you hit the daily limit.
            But if you want to continue please let us know which data you need.
          </p>
          <Button
            style={{ width: "100%" }}
            onClick={() => {
              setLimitModal(false);

              navigate("/contact-us");
            }}
          >
            Request Data
          </Button>
        </div>
      </Modal>
      <Modal
        open={popUpInitial}
        title={"Welcome"}
        closeBtn={true}
        setOpen={() => setPopUpInitial(false)}
      >
        <div className={Styles.ModalBody}>
          <p>
            We're happy to connect with you. If you want to request more
            specific data please click below.
          </p>
          <Button
            style={{ width: "100%" }}
            onClick={() => {
              setPopUpInitial(false);

              navigate("/contact-us");
            }}
          >
            Request Data
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default ETFs;
