import React from "react";
import Styles from "./Faq.module.css";
import DropDownList from "../../ComponentLayout/DropdownList/DropDownList";
const Faq = () => {
  const optionsList = [
    {
      title: "How Can I request more data?",
      text: "We provide data for thousands companies but, if you need more, feel free to request with the request button.",
    },
  ];
  return (
    <div className={Styles.Page}>
      <div className={Styles.PageContent}>
        <div className={Styles.PageTitle}>
          <h3>F.A.Q.</h3>
          <p>Our Website is designed to show latest fintech news.</p>
        </div>
        <DropDownList options={optionsList} />
      </div>
    </div>
  );
};

export default Faq;
