import React, { useEffect, useState } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Layout from "./Layout/Layout";
import LosersGainers from "./Pages/LosersGainers/LosersGainers";
import { jwtDecode } from "jwt-decode";
import { CircularProgress } from "@mui/material";
import ContactUs from "./Pages/ContactUs/ContactUs";
import Faq from "./Pages/Faq/Faq";
import BlogsDashboard from "./Pages/Blog/BlogsDashboard/BlogsDashboard";
import BlogPage from "./Pages/Blog/BlogPage/BlogPage";
import AddBlogPost from "./Pages/Blog/AddBlogPost/AddBlogPost";
import EditBlogPost from "./Pages/Blog/EditBlogPost/EditBlogPost";
import Login from "./Pages/Login/Login";
import ETFs from "./Pages/ETFs/ETFs";
import VolumeMovers from "./Pages/VolumeMovers/VolumeMovers";

const AppRoutes = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkAuth = () => {
      const token = localStorage.getItem("tokenLogin");
      if (token) {
        const decode = jwtDecode(token);
        if (decode.exp * 1000 < Date.now()) {
          setIsAuthenticated(false);
          localStorage.removeItem("tokenLogin");
          setLoading(false);
        } else {
          setIsAuthenticated(true);
          setLoading(false);
        }
      } else {
        setIsAuthenticated(false);
        setLoading(false);
      }
    };
    checkAuth();
  }, []);

  const RequireAuth = ({ children, redirectTo }) => {
    if (loading) {
      return <CircularProgress />;
    } else {
      return isAuthenticated ? children : <Navigate to={redirectTo} />;
    }
  };
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<LosersGainers />} />
          <Route path="/etfs" element={<ETFs />} />
          <Route path="/volume-movers" element={<VolumeMovers />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/faq" element={<Faq />} />
          <Route
            path="/blog"
            element={<BlogsDashboard isAuthenticated={isAuthenticated} />}
          />
          <Route path="/blog/:id" element={<BlogPage />} />
          <Route
            path="/blog/add"
            element={
              <RequireAuth redirectTo={"/login"}>
                <AddBlogPost />
              </RequireAuth>
            }
          />
          <Route
            path="/blog/edit/:id"
            element={
              <RequireAuth redirectTo={"/login"}>
                <EditBlogPost />
              </RequireAuth>
            }
          />
          <Route
            path="/login"
            element={
              <Login
                setIsAuthenticated={setIsAuthenticated}
                setLoading={setLoading}
                isAuthenticated={isAuthenticated}
              />
            }
          />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoutes;
