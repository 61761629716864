import React from "react";
import Sidebar from "../ComponentLayout/Sidebar/Sidebar";
import { Outlet } from "react-router-dom";
import Styles from "./Layout.module.css";

const Layout = () => {
  return (
    <div className={Styles.App}>
      <aside className={Styles.SideWrapper}>
        <Sidebar />
      </aside>
      <main className={Styles.MainWrapper}>
        <Outlet />
      </main>
    </div>
  );
};

export default Layout;
